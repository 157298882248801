<template>
  <div
    ref="elementRoot"
    :id="`select-products-block-swiper-${ selectProductsBlockSwiperId }`"
    class="swiper swiper-initialized swiper-horizontal swiper-android select-products-block-swiper"
  >
    <div class="swiper-wrapper">
      <template
        v-for="(product) in products"
        :key="`template-product-card-${ product.id }`"
      >
        <div class="swiper-slide">
          <div v-if="Boolean(product.characteristicGroupId === 19)">
            <CollectionCard
              :product="product"
              :is-disabled-swiper="isUsedZoom"
              :is-hide-images="!isElementVisible"
            />
          </div>
          <div v-else>
            <ProductCard
              :product="product"
              :is-disabled-swiper="isUsedZoom"
              :is-hide-images="!isElementVisible"
            />
          </div>
        </div>
      </template>
    </div>
    <div class="swiper-pagination-mobile"/>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import SwiperRoot from 'swiper';
import { Pagination } from 'swiper/modules';
import generateId from '~/utils/generateId';
import { useIntersectionObserver } from '@vueuse/core';
import ProductCard from '~/components/catalog/ProductCard.vue';
import CollectionCard from '~/components/catalog/CollectionCard.vue';
import type {IProduct} from '#sitis/internal/controllers/products/models/Product';

interface IProps {
	products: IProduct[];
  goal?: string;
}
const { $ym } = useNuxtApp()

const props = withDefaults(defineProps<IProps>(), {
	products: () => [],
  goal: () => '',
});
const isUsedDynamicBullets = computed(() => {
  return props.products.length > 20
});

let timeOutUsedZoom: any = null;
const isUsedZoom: Ref<boolean> = ref(false);
const checkUsedZoom = () => {
  if (!mobileSwiperRef.value) {
    return;
  }

  const currentScale = window.visualViewport?.scale || 1;
  if (!isUsedZoom.value && currentScale <= 1.1) {
    return;
  }

  if (currentScale <= 1.1) {
    isUsedZoom.value = false;
    mobileSwiperRef.value!.enable();
    return;
  }

  isUsedZoom.value = true;
  mobileSwiperRef.value!.disable();
};
const callCheckUsedZoom = () => {
  clearTimeout(timeOutUsedZoom);
  timeOutUsedZoom = setTimeout(() => {
    checkUsedZoom();
  }, 10);
};

const mobileSwiperRef: Ref<any> = ref(null);
const isElementVisible: Ref<boolean> = ref(false);
const elementRoot = ref<HTMLDivElement | null>(null);
const selectProductsBlockSwiperId: string = generateId(2);
const changeActiveSlide = () => {
  if (typeof $ym === 'function' && props.goal) {
    $ym('reachGoal', props.goal);
  }
}
const sliderInitial = () => {
  if (!isElementVisible.value) {
    return
  }

  const element: any = elementRoot;
  const res = new SwiperRoot(element.value, {
    modules: [Pagination],
    spaceBetween: 16,
    slidesPerView: 1.1,
    init: true,
    enabled: true,
    loop: (props.products || []).length > 1,
    breakpoints: {640: {slidesPerView: 2.2}},
    pagination: {
      el:'.swiper-pagination-mobile',
      enabled: true,
      type: 'bullets',
      dynamicBullets: Boolean(isUsedDynamicBullets.value)
    },
    on: {
      slideChange: changeActiveSlide
    }
  });
  mobileSwiperRef.value = res;
};
const { stop } = useIntersectionObserver(
  elementRoot,
  async ([{ isIntersecting }]) => {
    if (isIntersecting) {
      isElementVisible.value = isIntersecting;
      await nextTick();
      sliderInitial();
      // stop();
    } else {
      isElementVisible.value = false;
    }
  },
  {

  }
);
watch(() => isElementVisible.value, () => {
  if (!mobileSwiperRef.value) {
    return
  }

  if (isElementVisible.value) {
    mobileSwiperRef.value.enable();
  } else {
    mobileSwiperRef.value.disable();
  }
});

onMounted(() => {
  window.addEventListener("touchmove", callCheckUsedZoom)
});
onBeforeUnmount(() => {
  stop();
  window.removeEventListener("touchmove", callCheckUsedZoom)
});
</script>

<style lang="scss">
.select-products-block-swiper {
	margin: 0 -20px;
	padding: 0 20px;
	box-sizing: border-box;

  .swiper-slide {
    height: auto;
    display: flex;
    flex-direction: column;

    & > * {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .product-card {
      height: 100%;
    }
  }
	.swiper-pagination-mobile {
		position: relative;
		margin-top: 34px;

		& > * {
			opacity: 1;
			width: 10px !important;
			height: 10px !important;
			background-color: #EBEBEB;
      min-width: 10px;

			&.swiper-pagination-bullet-active {
				background-color: black;
			}

      &.swiper-pagination-bullet-active-main {
        background-color: black;
      }
		}
    &:not(.swiper-pagination-bullets-dynamic) {
      display: flex;
      justify-content: center;
    }
	}
}
</style>
